@import "../../../assets/scss/variable";
.btn-google {
  border: none;
  background-color: $google-color !important;
  border-color: $google-color !important;
  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 0px !important;
  &:hover {
    background-color: $google-hover-color !important;
    border-color: $google-hover-color !important;
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
  }
  &:active,
  &:hover {
    box-shadow: none;
  }
}

.google-icon {
  border: 1px solid $google-color;
  border-radius: $border-radius;
  .btn:focus,
  .btn:active,
  .btn:hover,
  .btn:focus-within,
  .btn:focus-visible {
    box-shadow: none !important;
  }
  &:focus,
  &:active,
  &:focus-within,
  &:focus-visible {
    box-shadow: 0 0 0 0.2rem rgba($google-color, 0.5) !important;
    transform: translate3d(0, 0, 0);
  }
}
