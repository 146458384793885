.dashboard .mini {
  font-size: 0.7rem;
}
.dashboard .title-color {
  color: #495057;
}

.dashboard .extra-small-font {
  font-size: x-small;
}

.dashboard .min-height-144 {
  min-height: 144px;
}

.dashboard .bg-cyan {
  background-color: cyan;
  border-radius: 6px;
  border: none;
}
.dashboard .badge {
  height: fit-content;
  border-radius: 7px;
  text-transform: capitalize;
}

.dashboard .bg-light-pink {
  background-color: lightpink;
  border-radius: 6px;
  border: none;
}
.dashboard .d-flex-border {
  border-left: 1px solid #eff2f7 !important;
}

/* .interview-card {
} */
.dashboard .progress {
  height: 1.2rem;
  border-radius: 0.3rem;
}

@media (min-width: 1030px) {
  .d-lg-flex-border {
    border-left: 1px solid #eff2f7 !important;
  }
}
.min-width-interview-tip {
  min-width: 200px;
}
.interview-status {
  display: flex;
  align-self: flex-start;
  margin-top: 10px;
}
.company-logo {
  height: 55px;
  width: 54px;
}

.card {
  height: inherit !important;
}

.dashboard .small,
small {
  font-size: 0.82222rem !important;
}
small {
  color: #646464;
}
.dashboard .progress {
  height: 1.2rem !important;
}

.dashboard .body-title {
  font-size: 1rem !important;
}
.dashboard .body-title-color {
  color: #495057 !important;
}
.dashboard .paragraph,
label,
p {
  font-size: 0.8777rem;
}

.dashboard .alert-primary {
  color: #00612b !important;
  background-color: #ccf1dc !important;
  border-color: #b8eccf !important;
}
.dashboard .flex-even {
  flex: 1 1;
}

/* ****** Badges */

.dashboard .badge {
  display: inline-block;
  padding: 0.4em;
  font-size: 75%;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
}

.dashboard .badge-danger {
  color: #ff4343;
  background-color: rgba(255, 67, 67, 0.15);
}

.dashboard .badge-blue {
  color: #5743d9;
  background-color: rgba(87, 67, 217, 0.15);
}

.dashboard .badge-info {
  color: #279ddf;
  background-color: rgba(39, 157, 223, 0.15);
}

.dashboard .badge-primary {
  color: #00612b !important;
  background-color: #ccf1dc !important;
}

.dashboard .btn {
  font-weight: unset;
}

.dashboard .interview-detail {
  min-width: 300px !important;
  width: 350px;
  border: 0;
  box-shadow: 0 10px 20px rgb(0 0 0 / 6%);
  border-radius: 6px;
  padding: 16px;
}
.dashboard .btn-link {
  font-weight: 400;
  color: #616161 !important;
  text-decoration: none;
}

.dashboard .dark-grey-color {
  color: #616161;
}

.dashboard  .dropdown-toggle:after {
  content: none !important;
}
