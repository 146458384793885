$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1030px,
  xl: 1440px
) !default;

$white: #fff !default;
$gray-50: #f6f6f6;
$gray-100: #f8f8fb;
$blue: #3c79e6;
$dark-blue: #5743D9;
$light-blue: #279DDF;
$light-gray: #b2b7bc;
$gray-600: #73767a;
$gray-200: #e9ecef;
$dark: #001c35;
$dark-grey: #616161;
$cyan: #c7ebe0;
$light-red: #FF4343;
$red: #ff0000;
$light-pink: #faeded;
$list-group-border-color: #D9D9D9;

$primary: #00ba52;
$secondary: #74788d;
$danger: $light-red !default;
$body-color: #495057;
$email-hover-color: #2a62c4;
$body-color-light: #646464;
$google-color: #eb4132;
$google-hover-color: #d93c2e;
$linked-in-color: #0077b7;
$linked-in-hover-color: #006ba5;
$auth-left-panel: #a6b0cf;
$digital-hire-drawer: $dark;
$light-digital-hire-drawer: rgba(0, 28, 53, 0.9);
$hover-link: #02a54a;
$footer-background: #F2F2F5;
$tooltip-background: $cyan;
$tooltip-text-color: #136749;
$loader-background: rgba(0, 28, 53, 0.1);
$invite-link-color: #09BABA;

$body-bg: $gray-100;
$subtitle-font-color: $secondary;

$enable-responsive-font-sizes: true;

$border-radius: 0.3rem;
$muted-font-size: 0.8em;
$badge-padding-y: 0.4em;

$font-weight-normal: 400;

$font-size-base: 1rem;

$font-family-poppins: Poppins;
$font-family-base: $font-family-poppins;
