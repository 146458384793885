@import "../../../assets/scss/_variable";

.video-record {
  max-width: 650px;
  video {
    width: 100%;
    &:focus {
      outline: none;
    }
  }
  .video-guide {
    height: auto;
    @media (min-width: 650px) {
      margin: 35px;
    }
    @media (max-width: 650px) {
      height: 60%;
    }
    position: absolute;
    animation: fadeIn 2s;
    visibility: visible;
  }
  .video-guide-hide {
    animation: fadeOut 2s;
    visibility: hidden;
  }
  .progress {
    width: 60px;
  }
  .under_part {
    background-color: #eee;
    background: transparent;
    z-index: 0;
  }
  .progress {
    position: relative;
    .border_container {
      position: absolute;
      display: flex;
      width: 100%;
      height: 100%;
    }

    .tenth_separator {
      height: 100%;
      width: 100%;
      border-right: 3px solid $white;
      z-index: 100;
      &:last-child {
        border-right: none;
      }
    }
    height: 17px;
    background-color: var(--progress-color);
    .progress-bar {
      width: var(--progress-bar-width);
      background-color: var(--progress-bar-color);
    }
  }
}

@keyframes fadeIn {
  0% {
    visibility: hidden;
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    visibility: visible;
    opacity: 1;
  }
  100% {
    visibility: hidden;
    opacity: 0;
  }
}
