@import "../../../assets/scss/_variable";

.fullscreen-loader {
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 99;
}

.overlay {
  background: $loader-background;
}

.loader-priority {
  z-index: 100;
}
